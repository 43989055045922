import * as Yup from "yup";

import ArraySchemaFactory from "./yupSchema/ArraySchemaFactory";
import StringSchemaFactory from "./yupSchema/StringSchemaFactory";
import getGfFieldId from "./getGfFieldId";

function getValidationSchemaByType(fieldData: any) {
  let schemaGetter = null;

  switch (fieldData?.type) {
    case "checkbox":
      schemaGetter = new ArraySchemaFactory(fieldData).schema;
      break;

    case "email":
      schemaGetter = new StringSchemaFactory(fieldData).schema;
      break;

    case "phone":
      schemaGetter = new StringSchemaFactory(fieldData).schema;
      break;

    case "text":
      schemaGetter = new StringSchemaFactory(fieldData).schema;
      break;

    case "select":
      schemaGetter = new StringSchemaFactory(fieldData).schema;
      break;

    case "website":
      schemaGetter = new StringSchemaFactory(fieldData).schema;
      break;

    default:
      return;
  }

  return schemaGetter;
}

/**
 * Create validation schema Object for a single field.
 *
 * @author Miew
 * @param  {object} fieldData GravityForm field props.
 * @return {object}           Field validation schema object.
 */
function getGfFieldValidationSchema(fieldData: any) {
  return {
    [getGfFieldId(fieldData.id)]: getValidationSchemaByType(fieldData),
  };
}

/**
 * Create validation schema Object for GravityForm.
 *
 * @param  {Array}  fields Array of fields.
 * @return {object}        Form validation schema object.
 */
export default function getGfFormValidationSchema(fields: any) {
  const formValidationSchema: any = {};

  if (!fields || !fields.length) {
    return formValidationSchema;
  }

  fields.forEach((field: any) => {
    if (!field.id) {
      return;
    }

    Object.assign(
      formValidationSchema,
      getGfFieldValidationSchema(field)
    );
  });

  return Yup.object(formValidationSchema);
}
