import { CheckRadioField, FormField } from "tempdev-ds";
import { ErrorMessage, Field } from "formik";

import CustomErrorMessage from "./CustomErrorMessage";
import { IRadio } from "../fieldProps";
import cn from "classnames";
import getGfFieldId from "@/lib/wordpress/forms/getGfFieldId";
import getGfHiddenClassName from "@/lib/wordpress/forms/getHiddenClassName";

export default function Radio({
  cssClass,
  choices,
  description,
  id,
  isRequired,
  inputs,
  label,
  visibility,
}: IRadio) {
  const fieldId = getGfFieldId(id);
  const isHiddenClass = getGfHiddenClassName(visibility);

  return (
    <FormField id={fieldId} label={label} isCheckRadio={true}>
      {choices &&
        choices.length > 0 &&
        choices.map((radio: any, index: number) => {
          const { id, text, value } = radio;

          return (
            <CheckRadioField
              key={`${fieldId}-${index}`}
              id={`${fieldId}-${index}`}
              label={text}
            >
              <Field
                type={"radio"}
                id={`${fieldId}-${index}`}
                name={fieldId}
                required={isRequired}
                value={value}
              />
            </CheckRadioField>
          );
        })}
      <ErrorMessage name={fieldId} component={CustomErrorMessage} />
    </FormField>
  );
}
