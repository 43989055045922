import { MouseEventHandler, useEffect } from "react";

export default function RenderReCaptcha() {
  useEffect(() => {
    const inputs = document.getElementsByTagName("input");
    const textareas = document.getElementsByTagName("textarea");

    var reCaptchaFocus = function (e: any) {
      if (!document.getElementById("recaptcha")) {
        var head = document.getElementsByTagName("head")[0];
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.id = "recaptcha";
        script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_PUBLIC_KEY}`;
        head.appendChild(script);
      }

      // remove focus to avoid js error:
      e.target.removeEventListener("focus", reCaptchaFocus, true);
    };

    if (inputs.length > 0) {
      [].slice.call(inputs).forEach((element: HTMLInputElement) => {
        element.addEventListener("focus", reCaptchaFocus, true);
      });
    }
    if (textareas.length > 0) {
      [].slice.call(textareas).forEach((element: HTMLInputElement) => {
        element.addEventListener("focus", reCaptchaFocus, true);
      });
    }
  }, []);
}
