import { IField, IGravityForms } from "../types";

/**
 * Process and format GF form field values for submission.
 *
 * Note: the following fields are currently unsupported in the project; if they are added, they will need custom handling for display and entry handling here:
 * - AddressField
 * - CaptchaField
 * - ChainedSelectField
 * - DateField
 * - EmailField with Email Confirmation enabled
 * - FileUploadField
 * - HiddenField
 * - ListField
 * - MultiSelectField
 * - NameField
 * - NumberField
 * - RadioField
 * - TimeField
 *
 * @see https://github.com/harness-software/wp-graphql-gravity-forms#submit-a-form
 * @author Miew
 * @param  {object} entryData GF form entry data.
 * @param  {object} fieldData GF form field config.
 * @return {Array}            Formatted GF field values.
 */
export default function processGfFieldValues({ entryData, fieldData }: IGravityForms) {
  const fieldValues = [] as any;
  const fields = [] as any;

  // Ensure fieldData is valid.
  if (!fieldData || !fieldData.length) {
    return fieldValues;
  }

  fieldData.forEach((field) => {
    if (!field?.id) {
      return;
    }

    // Add fields config to fields object with ID as key.
    fields[field.id] = field;
  });

  Object.keys(entryData as object).forEach((fieldName: any) => {
    // Extract first part of field name ("field-<ID>").
    const fieldNamePieces = fieldName.match(/^field-\d+/);

    // Ensure a field ID was found.
    if (!fieldNamePieces || !fieldNamePieces.length) {
      return;
    }

    // Extract field ID from field name.
    const fieldId = parseInt(fieldNamePieces[0].replace("field-", ""), 10);
    // Get field config by ID.
    const field = fields?.[fieldId];

    // Ensure field ID exists in field config.
    if (!field) {
      return;
    }

    let fieldValue = {} as any;

    // Skip if empty/undefined value.
    if (!entryData || !entryData[fieldName]) {
      return;
    }

    switch (field?.type) {
      case "checkbox":
        fieldValue.checkboxValues = [];
        if (Array.isArray(entryData[fieldName])) {
          return;
        }

        // Determine checkbox input IDs from index in field config choices array.
        field.checkboxChoices.forEach((choice: IField, index: number) => {
          if (!entryData[fieldName].includes(choice.value)) {
            return;
          }

          fieldValue.checkboxValues.push({
            inputId: parseFloat(`${fieldId}.${index + 1}`),
            value: choice.value,
          });
        });
        break;

      case "fileupload":
        fieldValue = entryData[fieldName];
        break;

      default:
        fieldValue = `${entryData[fieldName]}`;
        break;
    }

    fieldValues[`input_${fieldId}`] = fieldValue;
  });

  if (entryData && entryData["captcha"]) {
    fieldValues["captcha"] = entryData["captcha"]
  }

  return fieldValues;
}
