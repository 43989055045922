import { IField, IGravityForms } from "../types";

import processGfFieldValues from "./processGfFieldValues";

/**
 * Process GF form submission via Next API.
 *
 * @author Miew
 * @param  {number} formId    GF form ID.
 * @param  {object} entryData GF form entry data.
 * @param  {object} fieldData GF form field config.
 * @return {object}           Entry ID and confirmation message or error object.
 */
export default async function processGfFormSubmission({
  formId,
  entryData,
  fieldData
}: IGravityForms) {
  // Check data values.
  if (!formId || !parseInt(formId, 10)) {
    return {
      error: true,
      errorMessage: "Form ID is not valid.",
    };
  }

  if (!entryData) {
    return {
      error: true,
      errorMessage: "No form data provided.",
    };
  }

  const fieldValues = processGfFieldValues({entryData:entryData, fieldData:fieldData});

  const formData = new FormData();
  Object.keys(fieldValues).forEach(value => {
    formData.append(value, fieldValues[value] as string)
  });

  const data = await fetch(`${process.env.NEXT_PUBLIC_WP_API}gf/v2/forms/${formId}/submissions`, {
    // 14400 sec = 4h
    method: "post",
    next: { revalidate: 60, tags: ["wp"] },
    body: formData,
    // cache: preview ? 'no-store' : 'default'
    // headers: {
    //   // 'Content-Type': 'multipart/form-data'
    // },

  })


  if (!data.ok) {
    return {
      error: true,
      errorMessage: data.statusText,
    };
  }

  const response = await data.json();

  const { is_valid, confirmation_message } = response;

  if (!is_valid) {
    return {
      error: true,
      errorMessage:
        "An error occurred while attempting to process the form entry.",
    };
  }

  return {
    is_valid,
    confirmationMessage: confirmation_message,
  };
}
