import { IInputs } from "../fieldProps";
import cn from "classnames";
import getGfFieldId from "@/lib/wordpress/forms/getGfFieldId";

/**
 * Render the GravityForm Section component.
 *
 * @author Miew
 * @param  {object}  props                     GravityForm field props.
 * @param  {string}  props.cssClass           Classname string.
 * @param  {string}  props.description         GravityForm field description.
 * @param  {number}  props.id                  GravityForm unique field id.
 * @param  {string}  props.label               GravityForm field label.
 * @param  {string}  props.type                GravityForm field type.
 * @return {Element}                           The Section component.
 */
export default function Section({ cssClass, description, id, label, type }: IInputs) {
  const fieldId = getGfFieldId(id);

  return (
    <div
      className={cn("form-section w-100 mt-7", "section-" + fieldId, cssClass)}
    >
      <span className="d-block subtitle-l fw-bold">{label}</span>
      <hr className="mt-4 mb-4 border-primary50" />
      <p className="mb-5">{description}</p>
    </div>
  );
}