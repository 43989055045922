import { Field } from "formik";
import { IInputs } from "../fieldProps";
import getGfFieldId from "@/lib/wordpress/forms/getGfFieldId";

export default function Hidden({ id, isRequired, visibility }: IInputs) {
  const fieldId = getGfFieldId(id);

  return (
    <Field
      id={fieldId}
      name={fieldId}
      type={"hidden"}
      required={isRequired}
    ></Field>
  );
}
