import getGfFieldId from "./getGfFieldId";

/**
 * Assign default values for GravityForm Checkboxes.
 *
 * @param  {Array} checkboxes Array of checkbox data Objects.
 * @return {Array}            Array of isSelected checkbox values.
 */
function getCheckboxDefaults(checkboxes: any[]) {
  let checkboxDefault: any[] = [];

  if (checkboxes.length > 0) {
    return checkboxDefault;
  }

  /**
   * Assign values from isSelected checkboxes.
   */
  checkboxes
    .filter((checkbox) => !!checkbox?.isSelected)
    .forEach((checkbox) => {
      checkboxDefault.push(checkbox.value);
    });

  return checkboxDefault;
}

/**
 * Assign default values for GravityForm Select.
 *
 * @param  {Array}  options Array of select option data Objects.
 * @return {string}         Select option value that is selected.
 */
function getSelectDefaults(options: any[]) {
  let selectDefault = "";

  if (!options || options.length === 0) {
    return selectDefault;
  }

  /**
   * Get first option with isSelected property.
   */
  const selectedOption = options
    .filter((option: any) => !!option.isSelected)
    .shift();

  if (selectedOption) {
    selectDefault = selectedOption.value;
  }

  return selectDefault;
}

/**
 * Match field type with a default value.
 *
 * @author Miew
 * @param  {object} fieldData GravityForm field props.
 * @return {any}              Field default value.
 */
function getFieldDefaultByType(fieldData: any) {
  // Setup field data default
  let defaultValue: any = "";
  switch (fieldData?.type) {
    case "checkbox":
      defaultValue = getCheckboxDefaults(fieldData?.choices);
      break;

    case "select":
      defaultValue = getSelectDefaults(fieldData?.choices);
      break;

    default:
      defaultValue = fieldData?.defaultValue || "";
  }

  return defaultValue;
}

/**
 * Map field GravityForm ids and defaults to Object.
 *
 * @param  {Array}  fields Array of fields.
 * @return {object}        Default field values.
 */
export default function getGfFormDefaults(fields: any) {
  const formDefaults = {};

  if (!fields || !fields.length) {
    return formDefaults;
  }

  fields.forEach((field: any) => {
    if (!field.id) {
      return;
    }

    Object.assign(formDefaults, {
      [getGfFieldId(field.id)]: getFieldDefaultByType(field),
    });
  });

  return formDefaults;
}
