import { ErrorMessage, Field } from "formik";

import CustomErrorMessage from "./CustomErrorMessage";
import { FormField } from "tempdev-ds";
import { ISelect } from "../fieldProps";
import cn from "classnames";
import getGfFieldId from "@/lib/wordpress/forms/getGfFieldId";
import getGfHiddenClassName from "@/lib/wordpress/forms/getHiddenClassName";

/**
 * Render GravityForms Select field component.
 *
 * @param  {object}        props               GravityForm Select field as props.
 * @param  {string}        props.cssClass     GravityForm field wrapper class.
 * @param  {string}        props.description   GravityForm field description.
 * @param  {string|number} props.id            GravityForm field id.
 * @param  {boolean}       props.isRequired    GravityForm field is required.
 * @param  {string}        props.label         GravityForm field label.
 * @param  {Array}         props.choices GravityForm field selection options.
 * @param  {boolean}       props.visibility    GravityForm field visibility.
 * @return {Element}                           The Select component.
 */
export default function Select({
  cssClass,
  description,
  id,
  isRequired,
  label,
  placeholder,
  choices,
  visibility,
}: ISelect) {
  const fieldId = getGfFieldId(id);
  const isHiddenClass = getGfHiddenClassName(visibility);
  const thisClassName = cn(cssClass, isHiddenClass);

  return (
    <FormField label={label} id={fieldId} className={thisClassName}>
      <Field
        as="select"
        id={fieldId}
        name={fieldId}
        placeholder={placeholder}
        required={isRequired}
      >
        {choices &&
          choices.map((option, i) => {
            return (
              <option key={`option-${i}`} value={option.value}>
                {option.text}
              </option>
            );
          })}
      </Field>
      {description && <p>{description}</p>}
      <ErrorMessage name={fieldId} component={CustomErrorMessage} />
    </FormField>
  );
}
